import Highway from '@dogstudio/highway';
import ScrollFollow from '../modules/scrollFollow';

/* global tingle:true*/

export default class PageMiniSiteRenderer extends Highway.Renderer {

  onEnterCompleted() {

    if (window.DEVMODE) console.log('init Page Minisite');

    this.DOM = {view: this.wrap.lastElementChild};
    this.DOM.openSubNav = this.DOM.view.querySelector('.open-subnav-mobile');
    this.DOM.subNav = this.DOM.view.querySelector('.subnav-mobile');
    this.DOM.accordeonsItems = this.DOM.view.querySelectorAll('.accordion-element');
    this.DOM.documentsItems = this.DOM.view.querySelectorAll('.documents');
    this.DOM.modalsLinks = this.DOM.view.querySelectorAll('.link__modal');


    // SubNav
    this.DOM.openSubNav.addEventListener('click', (e) => {
      e.preventDefault();
      e.currentTarget.classList.toggle('active');
      this.DOM.subNav.classList.toggle('active');
    });


    this.initModals();
    this.initScrollFollow();
    this.initAccordeons();
    this.initDocuments();

  }

  initAccordeons() {

    // Accordeons
    if (!this.DOM.accordeonsItems) return;
    this.DOM.accordeonsItems.forEach((item) => {

      item.addEventListener('click', (e) => {
        e.preventDefault();

        e.currentTarget.classList.toggle('active');
        const panel = e.currentTarget.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    });

  }


  initDocuments() {

    // Accordeons
    if (!this.DOM.documentsItems) return;
    this.DOM.documentsItems.forEach((item) => {

      item.querySelector('h2').addEventListener('click', (e) => {
        e.preventDefault();
        e.currentTarget.parentNode.classList.toggle('active');
      });
    });

  }

  initModals() {

    if (!this.DOM.modalsLinks) return;
    this.DOM.modalsLinks.forEach((item) => {

      item.modal = new tingle.modal({
        footer: false,
        stickyFooter: false,
        closeMethods: ['overlay', 'escape'],
        onOpen: function onOpen() {

          if (this.modalSwiper) this.modalSwiper.update();

        },
        beforeClose: function onClose() {

          if (this.modalPlyr) this.modalPlyr.stop();
          return true;

        }
      });

      item.targetModal = item.getAttribute('href');
      item.modal.setContent(document.querySelector(`${item.targetModal}`).innerHTML);

      item.addEventListener('click', (e) => {
        e.preventDefault();
        item.modal.open();
      });

      // Init Close
      item.modal.modalBoxContent.querySelector('.modal__close').addEventListener('click', (e) => {
        e.preventDefault();
        item.modal.close();
      });

      // Init plyrs
      if (item.modal.modalBoxContent.querySelector('.modal__plyr')) {
        item.modal.modalPlyr = new Plyr(item.modal.modalBoxContent.querySelector('.modal__plyr'));
      }

      // Init Sliders
      if (item.modal.modalBoxContent.querySelector('.modal__swiper')) {
        item.modal.modalSwiper = new Swiper(item.modal.modalBoxContent.querySelector('.modal__swiper .swiper-container'), {
          navigation: {
            nextEl: item.modal.modalBoxContent.querySelector('.modal__swiper .swiper-controls .swiper-button-next'),
            prevEl: item.modal.modalBoxContent.querySelector('.modal__swiper .swiper-controls .swiper-button-prev')
          },
          on: {
            slideChange: function onSlideChange() {
              if (this.slides[this.previousIndex].querySelector('.modal__video') && item.modal.modalPlyr.playing) {
                item.modal.modalPlyr.stop();
              }
            }
          }
        });
      }

    });

  }

  initScrollFollow() {
    this.scrollfollows = new ScrollFollow();
  }

  onLeave() {

    this.scrollfollows.destroy();

  }

}
