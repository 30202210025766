export default class NotifsBar {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.closeNotif = this.DOM.el.querySelector('.notifs__close');

    if (window.DEVMODE) console.log('init Notifs Bar');

    this.addEvents();

  }

  addEvents() {

    this.DOM.closeNotif.addEventListener('click', (e) => {
      e.preventDefault();
      this.closeNotif();
    });

  }


  closeNotif() {

    TweenMax.to(this.DOM.el, 0.6, {
      y: '-100%',
      ease: Power3.easeInOut,
      onComplete: () => {
        this.DOM.el.parentNode.removeChild(this.DOM.el);
      }
    }, 0);

    console.log(this.DOM.el.offsetHeight);

    TweenMax.to(document.querySelector('body > .wrapper'), 0.6, {
      y: -1 * this.DOM.el.offsetHeight,
      ease: Power3.easeInOut,
      clearProps:"all"
    }, 0);

  }

}
