import FastClick from 'modern-fastclick';

import {newSniff} from './modules/sniffer.js';
import AccessibilityBar from './parts/accessibilityBar.js';
import NotifsBar from './parts/notifsBar.js';
import Search from './parts/search.js';
import Header from './parts/header.js';
import Footer from './parts/footer.js';
import Routes from './modules/routes.js';

window.DEVMODE = DEVMODE === 'development';
window.Sniff;

class App {

  constructor() {

    // Declarations
    this.sy = 0;
    this.sniffer;
    this.DOM = {};
    this.DOM.Body = document.body;
    this.DOM.App = this.DOM.Body.querySelector('.App');
    this.DOM.Header = this.DOM.Body.querySelector('.header');
    this.DOM.Footer = this.DOM.Body.querySelector('.footer');
    this.DOM.Loader = this.DOM.Body.querySelector('.loader');
    this.DOM.NotifsBar = this.DOM.Body.querySelector('.notifs__bar');
    this.DOM.Outdated = this.DOM.Body.querySelector('#Outdated');

    // Signature Wokine
    console.log('%cCreated by Wokine, with ❤', 'color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;');
    console.log('%c⚡ http://wokine.com ⚡', 'color:#ccc');

    this.init();
    // this.addEvents();

  }

  init() {

    // DevMode
    if (window.DEVMODE) console.log('DEVMODE 🛠');

    // Sniffer
    window.Sniff = newSniff();


    // OutdatedBrowser
    if (window.Sniff.browser.name === 'ie' && window.Sniff.browser.majorVersion <= 10) {
      console.log('<= ie010');
      this.DOM.Body.classList.remove('is-first');
      this.DOM.Body.classList.remove('is-loading');
      this.DOM.Outdated.classList.add('show');
      return;
    }

    // Chrome Scroll Manual
    this.DOM.Body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    history.scrollRestoration = 'manual';

    // Create Header/Footer/Routes
    this.Header = new Header(this.DOM.Header);
    this.Footer = new Footer(this.DOM.Footer);
    this.Routes = new Routes(this.Header);
    this.Search = new Search();
    this.AccessBar = new AccessibilityBar(this.Search);
    if (this.DOM.NotifsBar) this.NotifBar = new NotifsBar(this.DOM.NotifsBar);


    // DOMContentLoaded
    document.addEventListener('DOMContentLoaded', () => {

      // Add Fast Click
      new FastClick(document.body);
      // Reset Scroll
      window.scrollTo(0, 0);
      // Intro
      this.intro();

    }, false);

  }

  addEvents() {
    window.addEventListener('scroll', () => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
    });

  }

  intro() {

    const intro = new TimelineMax({
      paused: true,
      onStart: () => {
        this.DOM.Body.classList.remove('is-first');
        this.DOM.Body.classList.remove('is-loading');
      },
      onComplete: () => {
        this.DOM.Body.classList.remove('is-animating');
      },
      delay: 0.1
    });

    if (this.DOM.App.querySelector('[data-router-view]').dataset.pagecolor) {
      intro.to(this.DOM.Loader, 0.5, { backgroundColor: this.DOM.App.querySelector('[data-router-view]').dataset.pagecolor, ease: Power3.easeInOut }, 0);
    }

    //intro.fromTo(this.DOM.Loader.querySelector('.brand'), 1, { opacity: 0, scale: 1.2 }, { opacity: 1, scale: 1, ease: Power3.easeOut, clearProps: 'all' }, 0.5);
    intro.staggerFrom(this.DOM.Loader.querySelectorAll('.sprites svg path'), 1, { opacity: 0, y: 1000, x: -1000, ease: Power3.easeOut, clearProps: 'all' }, 0.03, 0);
    intro.fromTo(this.DOM.Loader.querySelector('.inner'), 1, { y: 0 }, { y: '75%', ease: Power3.easeInOut, clearProps: 'all' }, 1.3);
    intro.fromTo(this.DOM.Loader, 1, { y: 0 }, { y: '-100%', ease: Power3.easeInOut, clearProps: 'transform' }, 1.3);
    intro.staggerTo(this.DOM.Loader.querySelectorAll('.sprites svg path'), 1, { y: -1000, x: 1000, opacity: 0, ease: Power3.easeIn, clearProps: 'all' }, 0.03, 1);
    intro.timeScale(1.2).play();
  }

}

new App();
