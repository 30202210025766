import Highway from '@dogstudio/highway';

export default class PageHomeRenderer extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('Init Page Home');

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.logo = document.querySelector('.header .brand');
    this.pageBreakpoint = window.innerWidth >= 768;


    // Couleur du logo
    const logoColor = this.DOM.logo.dataset.color;
    this.DOM.logo.style.background = logoColor;


    // Add Horaires
    // document.querySelector('.header .horaires').classList.add('show');


    // Create Slider
    if (this.pageBreakpoint) this.createSwiper();
    window.addEventListener('resize', this.checkBreakpoint.bind(this));

  }

  onLeaveCompleted() {

    // RemoveLogoColor
    this.DOM.logo.style.background = '';

    // Delete Horaires
    // document.querySelector('header .horaires').classList.remove('show');

  }

  createSwiper() {

    /* global Swiper:true*/

    this.DOM.swiper = new Swiper('.swiper-container', {
      // Optional parameters
      loop: false,
      slidesPerView: 3,
      slidesOffsetAfter: 100,
      // Navigation arrows
      navigation: {
        nextEl: '.currently .arrows .swiper-button-next',
        prevEl: '.currently .arrows .swiper-button-prev'
      },
      breakpoints: {
        992: {
          slidesPerView: 1
        },
        1460: {
          slidesPerView: 2,
        },
        1680: {
          slidesPerView: 3,
          slidesOffsetAfter: 0
        }
      }
    });

  }

  checkBreakpoint() {

    this.pageBreakpoint = window.innerWidth >= 768;

    if (this.pageBreakpoint) {

      if (this.DOM.swiper !== undefined && this.DOM.swiper.destroyed === true) {
        // Recreate swiper
        this.createSwiper();
      }

    } else {

      if (this.DOM.swiper !== undefined && this.DOM.swiper.destroyed !== true) {
        // Destroy swiper
        this.DOM.swiper.destroy(true, true);
      }

    }
  }


}
