/* global gtag */

// Highway
import Highway from '@dogstudio/highway';
import PageHomeRenderer from '../pages/page-home.js';
import PageEditoRenderer from '../pages/page-edito.js';
import PageMiniSiteRenderer from '../pages/page-minisite.js';
import PageContactRenderer from '../pages/page-contact.js';
import PageDemandeMediatequeRenderer from '../pages/page-demande-mediateque.js';
import SingleActiviteRenderer from '../pages/single-activite.js';
import DefaultPageTransition from '../pages/page-transition.js';

import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageAnchors from './anchors.js';

export default class Routes {

  constructor(Header) {

    this.view = {};
    this.header = Header;
    this.navLinks = this.header.DOM.el.querySelectorAll('.nav__link');

    this.createHighway();
    this.initView(document.body);

  }

  createHighway() {

    const H = new Highway.Core({
      renderers: {
        pageHome: PageHomeRenderer,
        pageEdito: PageEditoRenderer,
        pageMinisite: PageMiniSiteRenderer,
        pageSingleActivite: SingleActiviteRenderer,
        pageContact: PageContactRenderer,
        pageMediateque: PageDemandeMediatequeRenderer
      },
      transitions: {
        default: DefaultPageTransition
      }
    });

    H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_OUT');
      }

      // RemovePrllx
      this.view.prllx.destroy();

      // RemoveModals
      const fromModals = document.querySelectorAll('.tingle-modal:not(.conditaionsTarifaires)');
      fromModals.forEach((modal) => {
        document.querySelector('body').removeChild(modal);
      });

    });

    H.on('NAVIGATE_IN', ({ to, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_IN');
      }

      // Menu
      if (trigger && trigger !== 'popstate') {

        if (trigger.classList.contains('brand')) {

          this.navLinks.forEach((link) => {
            link.classList.remove('is-active');
          });

        } else if (trigger.classList.contains('nav__link')) {

          this.navLinks.forEach((link) => {
            link.classList.remove('is-active');
            if (link.href === location.href) {
              link.classList.add('is-active');
            }
          });

        } else {

          this.navLinks.forEach((link) => {
            if (trigger.href === link.href) {
              link.classList.add('is-active');
            } else {
              link.classList.remove('is-active');
            }
          });

        }

      }

      this.initView(to.view);


      // Eval JS
      const js = to.view.querySelectorAll('script');
      if (js) {
        js.forEach((item) => {
          eval(item.innerHTML);
        });
      }


    });

    H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_END');
      }

      // Analytics
      if (window.tarteaucitron && typeof gtag !== 'undefined') {
        gtag('config', window.tarteaucitron.user.gtagUa, {
          'page_path': location.pathname,
          'page_title': to.page.title,
          'page_location': location.href
        });
      }

    });

  }

  initView(container) {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Menu Toggle
    if (this.header.menuOpen) {
      const click = new Event('click');
      this.header.DOM.toggleClose.dispatchEvent(click);
    }

    // Check Anchors
    if (container.querySelector('.anchors__nav')) {
      this.view.anchors = new pageAnchors(container.querySelector('.anchors__nav'));
    }

    // Prllx / InView
    this.view.prllx = new pagePrllx(container);
    this.view.inview = new pageInView(container);

  }

}
