import { getPosition } from '../utils';

export default class ScrollFollow {

  constructor() {

    this.scrollItems = document.querySelectorAll('[data-scrollfollow]');
    if (!this.scrollItems) return;

    this.sy = 0;

    this.init();
    this.addEvents();

  }

  init() {

    TweenMax.ticker.addEventListener('tick', this.renderScrollFollows, this, true, 1);

  }

  addEvents() {

    window.addEventListener('scroll', (e) => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
    });

  }

  renderScrollFollows() {

    if (window.innerWidth < 992) return;

    this.scrollItems.forEach((item) => {

      if (item.getBoundingClientRect().y + this.sy - 40 <= this.sy) {

        if (this.sy <= item.getBoundingClientRect().y + this.sy + item.parentNode.offsetHeight - item.offsetHeight) {
          item.y = -item.getBoundingClientRect().y + 40;
        }

      } else {
        item.y = 0;
      }

      TweenMax.set(item.querySelectorAll('[data-scrollfixedcontent]'), {y: item.y});


    });

  }

  destroy() {


    TweenMax.ticker.removeEventListener('tick', this.renderScrollFollows);

  }

}
