import Highway from '@dogstudio/highway';
import scrollfollow from '../modules/scrollFollow';

export default class PageDemandeMediatequeRenderer extends Highway.Renderer {

  onEnterCompleted() {

    if (window.DEVMODE) console.log('Init Page Demande Mediatèque');

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.openSubNav = this.DOM.view.querySelector('.open-subnav-mobile');
    this.DOM.subNav = this.DOM.view.querySelector('.subnav-mobile');
    this.DOM.accordions = this.DOM.view.querySelectorAll('.accordion-element');

    this.events = {};
    this.events.scrollfollows = new scrollfollow();


    // Accordeons
    this.DOM.accordions.forEach((item) => {

      item.addEventListener('click', (e) => {
        e.preventDefault();

        e.currentTarget.classList.toggle('active');
        const panel = e.currentTarget.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    });


    // SubNav
    this.DOM.openSubNav.addEventListener('click', (e) => {
      e.preventDefault();
      e.currentTarget.classList.toggle('active');
      this.DOM.subNav.classList.toggle('active');
    });

    // Add Form
    this.DOM.form = this.DOM.view.querySelector('#formMediatheque');
    this.DOM.output = this.DOM.view.querySelector('.output');
    this.renderCaptchaWait();

  }

  initForm() {

    const captchaSuccess = () => {

      document.body.classList.add('is-loading');
      this.DOM.output.innerHTML = '';


      const values = {
        name: document.getElementById("name").value,
        first_name: document.getElementById("first_name").value,
        email: document.getElementById("email").value,
        company: document.getElementById("company").value,
        recaptcha: document.getElementById("g-recaptcha-response").value,
      }

      const http = new XMLHttpRequest();
      const url = this.DOM.form.dataset.ajaxurl;

      http.open('POST', url, true);

      http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

      http.onreadystatechange = () => {
        // Call a function when the state changes.
        if (http.readyState === 4) {
          if ( http.status !== 200) this.DOM.output.classList.add('error');
          else this.DOM.output.classList.remove('error');

          this.DOM.output.innerHTML = http.responseText;
          document.body.classList.remove('is-loading');
          this.DOM.form.reset()

        }
      };

      http.send(JSON.stringify(values));
      grecaptcha.reset();

    };

    const grecaptchaElem = this.DOM.view.querySelector('.g-recaptcha');
    grecaptcha.render(grecaptchaElem, {
      'sitekey': grecaptchaElem.dataset.sitekey,
      'callback': captchaSuccess,
      'size': grecaptchaElem.dataset.size
    });

    this.DOM.form.addEventListener('submit', (e) => {
      e.preventDefault();
      grecaptcha.execute();
    });

  }

  renderCaptchaWait() {
    setTimeout(() => {
      if (typeof grecaptcha !== "undefined" && typeof grecaptcha.render !== "undefined") {
        this.initForm();
      } else {
        this.renderCaptchaWait();
      }
    }, 200);
  }

  onLeave() {

    this.events.scrollfollows.destroy();

  }

}
