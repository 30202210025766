
export default class Header {

  constructor(el) {

    this.DOM = {el: el};
    this.DOM.body = document.body;
    this.DOM.brand = this.DOM.el.querySelector('.brand');
    this.DOM.toggleOpen = this.DOM.el.querySelector('.toggle__menu__open');
    this.DOM.toggleClose = this.DOM.el.querySelector('.toggle__menu__close');
    this.DOM.menuContainer = this.DOM.el.querySelector('.nav__container');
    this.menuOpen = false;


    this.init();
    this.addEvents();
  }

  init() {

    if (window.DEVMODE) console.log('init Header');

    // Toggle Open
    this.DOM.toggleOpen.addEventListener('click', (e) => {
      e.preventDefault();
      this.open();
    });

    // Toggle Close
    this.DOM.toggleClose.addEventListener('click', (e) => {
      e.preventDefault();
      this.close();
    });

  }

  addEvents() {

    window.addEventListener('resize', () => {
      if (window.innerWidth > 1200 && this.DOM.body.classList.contains('showMenu')) {
        this.close();
      }
    });
  }

  open() {

    return new Promise((resolve, reject) => {

      // Console.log('Open Menu');
      this.menuOpen = true;
      this.DOM.body.classList.add('showMenu');
      this.DOM.body.style.overflow = 'hidden';

    });

  }

  close() {

    return new Promise((resolve, reject) => {

      // Console.log('Close Menu');
      this.menuOpen = false;
      this.DOM.body.classList.remove('showMenu');
      this.DOM.body.style.overflow = '';

    });

  }


}
