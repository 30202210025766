export default class Footer {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.App = document.body.querySelector('.App');
    this.DOM.conditionsTarifaireslink = this.DOM.el.querySelectorAll('.link__modal__conditions');
    this.conditionsTarifaires = document.getElementById('conditions_tarifaires').innerHTML;

    this.init();

  }

  init() {

    if (window.DEVMODE) console.log('init Footer');
    this.initConditionsTarifaires();

  }

  initConditionsTarifaires() {

    const modal = new tingle.modal({
      cssClass: ['conditaionsTarifaires']
    });
    modal.setContent(this.conditionsTarifaires);
    this.DOM.conditionsTarifaireslink.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        modal.open();
      });
    });

  }

}
