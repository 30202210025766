import inView from 'in-view';

export default class Bubbles {

  constructor(canvas) {

    this.canvas = canvas;
    this.ctx = this.canvas.getContext('2d');
    this.bubbles;

    this.init();

  }

  init() {

    // Console.log('Init bubbles');
    this.resizeCanvas();
    this.bubbles = this.createBubbles();

    // Invew
    inView(`#${this.canvas.id}`).on('enter', el => {
      TweenMax.ticker.addEventListener('tick', this.render, this, true, 1);
    }).on('exit', el => {
      TweenMax.ticker.removeEventListener('tick', this.render, this, true, 1);
    });

  }

  render() {
    this.resizeCanvas();
    this.drawBubbles();
  }

  createBubbles() {
    const bubbles = [];
    const count = 20;
    const minRad = 10;
    const maxRad = 100;
    const minSpeed = 3;
    const maxSpeed = 10;
    const color = '#e0e6ee';

    for (let x = 0; x < count; x++) {

      const u = this.randomNum(0, this.canvas.width);
      const v = this.randomNum(0, this.canvas.height);

      bubbles.push({
        radius: this.randomNum(minRad, maxRad),
        directionX: this.randomNum(-1, 1) || 1,
        directionY: this.randomNum(-1, 1) || 1,
        speed: this.randomNum(minSpeed, maxSpeed) / 10,
        position: [u, v],
        color: color
      });

    }

    return bubbles;

  }

  drawBubbles() {

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Draw bubbles
    this.bubbles.forEach(bubble => {

      const [u, v] = bubble.position;

      bubble.directionX = this.changeSettings(u, 0, this.canvas.width) || bubble.directionX;
      bubble.directionY = this.changeSettings(v, 0, this.canvas.height) || bubble.directionY;

      const x = u + bubble.speed * bubble.directionX;
      const y = v + bubble.speed * bubble.directionY;
      bubble.position = [x, y];

      this.ctx.beginPath();
      this.ctx.arc(x, y, bubble.radius, 0, Math.PI * 2, false);
      this.ctx.fillStyle = bubble.color;
      this.ctx.fill();

    });


  }

  resizeCanvas() {

    // const realToCSSPixels = window.devicePixelRatio;

    // Lookup the size the browser is displaying the canvas.
    const displayWidth = Math.floor(this.canvas.clientWidth);
    const displayHeight = Math.floor(this.canvas.clientHeight);

    // Check if the canvas is not the same size.
    if (this.canvas.width !== displayWidth || this.canvas.height !== displayHeight) {

      // Make the canvas the same size
      this.canvas.width = displayWidth;
      this.canvas.height = displayHeight;
    }
  }

  destroy() {

    console.log('destroy');
    TweenMax.ticker.removeEventListener('tick', this.render);

  }

  randomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  changeSettings(setting, min, max, prob) {

    if (setting < min) {
      return 1;
    } else if (setting > max) {
      return -1;
    }

    return 0;

  }


}
