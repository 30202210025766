// Highway
import Highway from '@dogstudio/highway';

export default class DefaultPageTransition extends Highway.Transition {

  in({ from, to, trigger, done }) {

    from.parentNode.removeChild(from);


    const pageContent = to.querySelector('.page__content');

    if (pageContent) {

      const pageIn = new TimelineMax({
        paused: true,
        onStart: () => {
          document.body.classList.remove('is-loading');
        },
        onComplete: () => {
          done();
        }
      });

      if (to.querySelector('.page--head .title')) pageIn.fromTo(to.querySelector('.page--head .title'), 1, { opacity:0, y: 60 }, { opacity:1, y: 0, ease: Power3.easeOut, clearProps: 'all' }, 0);
      pageIn.fromTo(to.querySelector('.page__content'), 1, { y: 80 }, { y: 0, ease: Power3.easeOut, clearProps: 'all' }, 0);
      pageIn.play();

    } else {
      document.body.classList.remove('is-loading');
      done();
    }

    /*const Loader = document.querySelector('.loader');

    // Remove Last Page
    from.parentNode.removeChild(from);

    // Timeline animation page enter
    const pageIn = new TimelineMax({
      paused: true,
      onStart: () => {
        document.body.classList.remove('is-loading');
      },
      onComplete: () => {
        document.body.classList.remove('is-animating');
        done();
      }
    });

    if (to.dataset.pagecolor) {
      pageIn.to(Loader, 0.5, { backgroundColor: to.dataset.pagecolor, ease: Power3.easeInOut }, 0);
    }

    pageIn.fromTo(Loader.querySelector('.inner'), 1, { y: 0 }, { y: '75%', ease: Power3.easeInOut, clearProps: 'all' }, 0.5);
    pageIn.staggerTo(Loader.querySelectorAll('.sprites svg path'), 1, { y: -1000, x: 1000, opacity: 0, ease: Power3.easeIn, clearProps: 'all' }, 0.03, 0);
    pageIn.fromTo(Loader, 1, { y: 0 }, { y: '-100%', ease: Power3.easeInOut, clearProps: 'transform' }, 0.5);
    pageIn.fromTo(to, 1, { y: 100 }, { y: 0, ease: Power3.easeInOut, clearProps: 'all' }, 0.5);
    pageIn.timeScale(1.2).play();*/

  }

  out({ from, trigger, done }) {

    document.body.classList.add('is-loading');
    done();

    /*const Loader = document.querySelector('.loader');

    // Timeline animation page leave
    const pageOut = new TimelineMax({
      paused: true,
      onStart: () => {
        document.body.classList.add('is-loading');
        document.body.classList.add('is-animating');
      },
      onComplete: () => {
        done();
      }
    });

    pageOut.fromTo(Loader.querySelector('.inner'), 1, { y: '-75%' }, { y: '0%', ease: Power3.easeInOut }, 0);
    pageOut.staggerFrom(Loader.querySelectorAll('.sprites svg path'), 1, { y: 1000, x: -1000, opacity: 0, ease: Power3.easeOut, clearProps: 'all' }, 0.03, 0);
    pageOut.fromTo(Loader, 1, { y: '100%' }, { y: '0%', ease: Power3.easeInOut }, 0);
    pageOut.to(from, 1, { y: -20, ease: Power3.easeInOut, clearProps: 'all' }, 0);

    pageOut.play();*/

  }

}
