import Highway from '@dogstudio/highway';
import { getPosition } from '../utils';
import scrollfollow from '../modules/scrollFollow';

export default class PageEditoRenderer extends Highway.Renderer {

  onEnterCompleted() {

    if (window.DEVMODE) console.log('Init Page Édito');

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.openSubNav = this.DOM.view.querySelector('.open-subnav-mobile');
    this.DOM.subNav = this.DOM.view.querySelector('.subnav-mobile');
    this.DOM.accordions = this.DOM.view.querySelectorAll('.accordion-element');

    this.events = {};
    this.events.scrollfollows = new scrollfollow();

    // Accordeons
    this.DOM.accordions.forEach((item) => {

      item.addEventListener('click', (e) => {
        e.preventDefault();

        e.currentTarget.classList.toggle('active');
        const panel = e.currentTarget.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    });

    // SubNav
    this.DOM.openSubNav.addEventListener('click', (e) => {
      e.preventDefault();
      e.currentTarget.classList.toggle('active');
      this.DOM.subNav.classList.toggle('active');
    });

    // AnchorScroll
    if (window.location.hash) {
      const hashTop = getPosition(document.querySelector(window.location.hash)).y - 40;
      TweenMax.to(window, 1.2, { scrollTo: { y: hashTop, autoKill: false }, ease: Power3.easeInOut, delay:1 });
    }

  }

  onLeave() {

    this.events.scrollfollows.destroy();

  }

}
