import Sniffer from 'snifferjs';

/**
* Adds two numbers together.
* @returns {Sniff} Objet Sniffer.
 */
export function newSniff() {

  const Sniff = new Sniffer(navigator.userAgent);
  const htmlNode = document.getElementsByTagName('html');
  const classNameArr = [htmlNode.className];

  Sniff.browser.name && classNameArr.push(Sniff.browser.name);
  Sniff.browser.engine && classNameArr.push(Sniff.browser.engine);
  Sniff.os.name && classNameArr.push(Sniff.os.name);

  for (const prop in Sniff.features) {
    if (Sniff.features[prop]) classNameArr.push(prop);
  }

  htmlNode[0].className = classNameArr.join(' ');

  if (window.DEVMODE) console.log('Sniffer :', Sniff);

  return Sniff;

}
