export default class AccessibilityBar {

  constructor(search) {

    this.DOM = { el: document.querySelector('.accessibility__bar') };
    this.DOM.anchors = this.DOM.el.querySelector('.accessibility__anchors');
    this.DOM.dyslexie = this.DOM.el.querySelector('.accessibility__dyslexie');
    this.DOM.sizes = this.DOM.el.querySelector('.accessibility__sizes');
    this.currentSize = 62.5;
    this.Search = search;

    this.init();

  }

  init() {

    if (window.DEVMODE) console.log('init Accessibility Bar');

    // Anchors
    this.DOM.anchors.querySelector('[data-focus-content]').addEventListener('click', this.showContent);
    this.DOM.anchors.querySelector('[data-focus-menu]').addEventListener('click', this.showMenu);
    this.DOM.anchors.querySelector('[data-focus-research]').addEventListener('click', (e) => {
      this.showSearch(e);
    });
    this.DOM.dyslexie.querySelector('[data-font-toggle]').addEventListener('click', this.switchDislexia);

    const fontSizeLinks = this.DOM.sizes.querySelectorAll('a');
    fontSizeLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        this.switchFontSize(e.currentTarget.dataset.fontSize);
      });
    });

  }


  showContent(e) {
    e.preventDefault();

    // ScrollToContent
    const target = document.querySelector('[data-target-content]');
    if (target) TweenMax.to(window, 0.8, { scrollTo: { y: target.offsetTop - 20, autoKill: false }, ease: Power3.easeOut });
  }


  showMenu(e) {
    e.preventDefault();

    // ScrollToMenu
    const target = document.querySelector('[data-target-menu]');
    TweenMax.to(window, 0.8, { scrollTo: { y: target.offsetTop - 20, autoKill: false }, ease: Power3.easeOut });

    // HoverMenuLinks
    const navLinks = target.querySelectorAll('.nav__link');
    TweenMax.staggerTo(navLinks, 1, { className: '+=is-active', ease: Power3.easeOut, delay: 0.4 }, 0.1);
    TweenMax.staggerTo(navLinks, 0.6, { className: '-=is-active', ease: Power3.easeOut, delay: 1.4 }, -0.1);
  }


  showSearch(e) {
    e.preventDefault();
    this.Search.openSearch();
  }


  switchDislexia(e) {
    e.preventDefault();

    if (e.currentTarget.classList.contains('is--active')) {
      e.currentTarget.classList.remove('is--active');
      document.querySelector('html').classList.remove('is--dislexia');
    } else {
      e.currentTarget.classList.add('is--active');
      document.querySelector('html').classList.add('is--dislexia');
    }
  }


  switchFontSize(size) {

    if (size === 'decrease') this.currentSize = this.currentSize * 0.9;
    if (size === 'default') this.currentSize = 62.5;
    if (size === 'increase') this.currentSize = this.currentSize * 1.1;

    document.querySelector('html').style.fontSize = `${this.currentSize}%`;

  }
}
