export default class Search {

  constructor() {

    this.DOM = { el: document.querySelector('.global-search') };
    this.DOM.body = document.querySelector('body');
    this.DOM.toggleSearchOpen = document.querySelector('.toggle__search__open');
    this.DOM.toggleSearchClose = document.querySelector('.toggle__search__close');
    this.DOM.searchBG = this.DOM.el.querySelector('.bg');
    this.DOM.searchInput = document.getElementById('search_global');
    this.DOM.searchForm = document.getElementById('search_mini_form');
    this.DOM.searchNoResults = document.getElementById('no-result');
    this.DOM.searchResults = document.getElementById('results-global');

    this.searchOpen = false;

    this.init();
    this.addEvents();

  }

  init() {

    if (window.DEVMODE) console.log('init Search Bar');

  }

  addEvents() {


    // Search Open
    this.DOM.toggleSearchOpen.addEventListener('click', (e) => {
      e.preventDefault();
      this.openSearch();
    });


    // Search Close
    this.DOM.toggleSearchClose.addEventListener('click', (e) => {
      e.preventDefault();
      this.closeSearch();
    });


    // BGClose
    this.DOM.searchBG.addEventListener('click', (e) => {
      e.preventDefault();
      this.closeSearch();
    });


    // Form Submit
    this.DOM.searchForm.addEventListener('submit', (e) => {
      e.preventDefault();

      const url = this.DOM.searchForm.dataset.ajaxurl;
      const value = this.DOM.searchInput.value;

      const http = new XMLHttpRequest();
      http.open('GET', `${url}&s=${value}`, true);

      // Add Loading Class
      this.DOM.searchResults.classList.add('hide');
      this.DOM.searchNoResults.classList.add('hide');
      this.DOM.searchForm.classList.add('is--loading');

      http.onreadystatechange = () => {
        // Call a function when the state changes.
        if (http.readyState === 4 && http.status === 200) {
          if (http.responseText !== '') {
            this.DOM.searchResults.innerHTML = http.responseText;

            TweenMax.staggerFromTo(this.DOM.searchResults.querySelectorAll('.result-card'), 1, { y: 20, opacity:0 }, { y:0, opacity:1, ease: Power3.easeInOut, clearProps: 'all', onStart:()=> {
              this.DOM.searchResults.classList.remove('hide');
            }}, 0.2);

          } else {
            this.DOM.searchNoResults.classList.remove('hide');
          }

          this.DOM.searchForm.classList.remove('is--loading');
        }
      };

      http.send(null);

    });

  }

  openSearch() {

    return new Promise((resolve, reject) => {

      // console.log('Open Search');
      this.searchOpen = true;
      this.DOM.body.classList.add('show-search');
      this.DOM.body.style.overflow = 'hidden';

      // Focus Input
      setTimeout(() => {
        this.DOM.searchInput.focus();
      }, 500);

    });

  }

  closeSearch() {

    return new Promise((resolve, reject) => {

      // console.log('Close Search');
      this.searchOpen = true;
      this.DOM.body.classList.remove('show-search');
      this.DOM.body.style.overflow = '';
      this.DOM.searchInput.blur();

    });

  }

}
