import Highway from '@dogstudio/highway';
import Bubbles from '../modules/bubbles';
import ScrollFollow from '../modules/scrollFollow';
import { getPosition } from '../utils';

/* global Swiper:true tingle:true*/

export default class SingleActiviteRenderer extends Highway.Renderer {

  onEnterCompleted() {

    if (window.DEVMODE) console.log('Init Single Activité');

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.scrollToBtn = this.DOM.view.querySelector('.page-activite--head .scroll__to');
    this.DOM.horairesToBtn = this.DOM.view.querySelector('.anchor__item');
    this.DOM.plyrs = this.DOM.view.querySelectorAll('.video__plyr');
    this.DOM.photoSwiper = this.DOM.view.querySelector('.photos .swiper-container');
    this.DOM.activitySwiper = this.DOM.view.querySelector('.activite-section .swiper-container');
    this.DOM.bubbles = this.DOM.view.querySelector('#bubbles');
    this.DOM.modalsLinks = this.DOM.view.querySelectorAll('.link__modal');

    this.addEvents();
    this.initPlyrs();
    this.initSwipers();
    this.initBubbles();
    this.initModals();
    this.initScrollFollow();

  }

  addEvents() {

    // Scroll Btn;
    this.DOM.scrollToBtn.addEventListener('click', (e) => {
      e.preventDefault();
      TweenMax.to(window, 0.8, { scrollTo: { y: this.DOM.view.querySelector('[data-target-content]').offsetTop - 20, autoKill: false}, ease: Power3.easeInOut })
    });

    // Horaires Btn;
    this.DOM.horairesToBtn.addEventListener('click', (e) => {
      e.preventDefault();
      const target = e.currentTarget.getAttribute('href');
      const targetY = getPosition(this.DOM.view.querySelector(target)).y;
      TweenMax.to(window, 0.8, { scrollTo: { y: targetY, autoKill: false}, ease: Power3.easeInOut })
    });

  }

  // Init initPlyrs
  initPlyrs() {

    if (!this.DOM.plyrs) return;

    this.DOM.plyrs.forEach((item) => {
      const player = new Plyr(item);
    });

  }

  // Init Swiper
  initSwipers() {


    // Photos Swiper
    if (this.DOM.photoSwiper) {

      const photosActivitesSwiper = new Swiper(this.DOM.photoSwiper, {
        // Optional parameters
        slidesPerView: 2,
        grabCursor: true,
        loop: true,
        lazy: true
      });

      photosActivitesSwiper.on('touchStart', () => {
        document.querySelector('.drag').classList.add('disapear');
      });

      photosActivitesSwiper.on('touchEnd', () => {
        document.querySelector('.drag').classList.remove('disapear');
      });

    }

    // Activités Swiper
    if (this.DOM.activitySwiper) {

      const activitesSwiper = new Swiper(this.DOM.activitySwiper, {
        // Optional parameters
        grabCursor: true,
        lazy: true,
        navigation: {
          nextEl: '.swiper__container .swiper-controls .swiper-button-next',
          prevEl: '.swiper__container .swiper-controls .swiper-button-prev'
        }
      });

    }


  }

  // Init Bubbles
  initBubbles() {

    if (!this.DOM.bubbles) return;
    this.pageBubbles = new Bubbles(this.DOM.bubbles);

  }

  // Init Modals
  initModals() {

    if (!this.DOM.modalsLinks) return;

    this.DOM.modalsLinks.forEach((item) => {

      item.modal = new tingle.modal({
        footer: false,
        stickyFooter: false,
        closeMethods: ['overlay', 'escape']
      });

      item.targetModal = item.getAttribute('href');
      item.modal.setContent(document.querySelector(`${item.targetModal}`).innerHTML);

      item.addEventListener('click', (e) => {
        e.preventDefault();
        item.modal.open();
      });

      // Init Close
      item.modal.modalBoxContent.querySelector('.modal__close').addEventListener('click', (e) => {
        e.preventDefault();
        item.modal.close();
      });

    });

  }


  // Init ScrollFollow
  initScrollFollow() {
    this.scrollfollows = new ScrollFollow();
  }


  onLeave() {
    if (!this.DOM.bubbles) this.pageBubbles.destroy();
    this.scrollfollows.destroy();
  }

}
